import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MBusConfig, MBusDataPoint } from '../../../../domain/device.interface';
import { DeploymentService } from '../../../../services/deployment.service';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from '../../../../services/notification.service';
import { OPCUAConfig } from '../../../../domain/opcua-config';

@Component({
  selector: 'eis-gateway-opcua-config-table',
  templateUrl: './opcua-config-table.component.html',
  styleUrls: ['./opcua-config-table.component.scss']
})
export class OpcuaConfigTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  public gatewaySerial: string | undefined;

  @Input()
  public configs: MatTableDataSource<OPCUAConfig>;

  @Output()
  public updateDatasource = new EventEmitter<string>();

  public displayedColumns: string[] = ['deviceName', 'browseName', 'pollingFrequency']

  constructor(private deploymentService: DeploymentService,
              private router: Router,
              private clipboard: Clipboard,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
  }

  refresh(): void {
    this.table.renderRows();
  }

  async copyLabelsToClipboard(labels: string) {
    if(!labels) {
      return;
    }

    this.clipboard.copy(labels);
    await this.notificationService.success("Copied to clipboard");
  }

  ngAfterViewInit(): void {
    this.configs.sort = this.sort;
  }
}
