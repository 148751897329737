import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import { OPCUADevice } from '../domain/opcua-device';
import { triggerLoader } from '../components/decorators/loaderDecorator';
import { checkFullyConnected } from '../components/decorators/checkConnectedDecorator';
import { OPCUATreeNode } from '../domain/opcua-tree-node';
import { OPCUADataPoint } from '../domain/opcua-data-point';
import { OPCUAConfig } from '../domain/opcua-config';

@Injectable({
  providedIn: 'root'
})
export class OPCUADeviceService {
  private readonly SERVICE_URL = `${environment.backendUrl}/opcua`;

  constructor(private http: HttpClient) {
  }

  public getAllDevices(gatewaySerial?: string): Observable<OPCUADevice[]> {
    return this.http.get<OPCUADevice[]>(this.SERVICE_URL + '/' + gatewaySerial);
  }

  @triggerLoader
  @checkFullyConnected
  addDevice(device: OPCUADevice) {
    return this.http.post<OPCUADevice>(this.SERVICE_URL, device) as Observable<OPCUADevice>;
  }

  updateDevice(device: OPCUADevice) {
    return this.http.put<OPCUADevice>(this.SERVICE_URL, device) as Observable<OPCUADevice>;
  }

  deleteDevice(device: OPCUADevice) {
    return this.http.delete(this.SERVICE_URL + '/' + device.id);
  }

  @triggerLoader
  @checkFullyConnected
  verifyDeviceConnection(device: OPCUADevice) {
    return this.http.post<boolean>(this.SERVICE_URL + '/verify', device) as Observable<boolean>;
  }

  @triggerLoader
  @checkFullyConnected
  getDataPoints(device: OPCUADevice, id: string) {
    return this.http.post<OPCUADataPoint[]>(this.SERVICE_URL + '/datapoints?nodeId=' + id, device) as Observable<OPCUADataPoint[]>;
  }

  browseChildren(device: OPCUADevice, id: string) {
    return this.http.post<OPCUATreeNode[]>(this.SERVICE_URL + '/children?nodeId=' + id, device) as Observable<OPCUATreeNode[]>;
  }

  getDeviceConfig(gatewaySerial: string, deviceId: string) {
    return this.http.get<OPCUAConfig[]>(this.SERVICE_URL + '/config/' + gatewaySerial + '/' + deviceId) as Observable<OPCUAConfig[]>;
  }

  @triggerLoader
  @checkFullyConnected
  updateDeviceConfig(gatewaySerial: string, deviceId: string, configs: OPCUAConfig[]) {
    return this.http.post<OPCUAConfig[]>(this.SERVICE_URL + '/config/' + gatewaySerial + '/' + deviceId, configs) as Observable<OPCUAConfig[]>;
  }

  @triggerLoader
  @checkFullyConnected
  removeDeviceConfig(gatewaySerial: string, deviceId: string, configs: OPCUAConfig[]) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: configs  // Pass the list of objects in the body
    };
    return this.http.delete(this.SERVICE_URL + '/config/' + gatewaySerial + '/' + deviceId, httpOptions);
  }

  getGatewayConfig(gatewaySerial: string, deviceIds: string[]) {
    const ids = deviceIds.join(",");
    return this.http.get<OPCUAConfig[]>(this.SERVICE_URL + '/gateway/' + gatewaySerial + '/configs?ids=' + ids) as Observable<OPCUAConfig[]>;
  }
}
