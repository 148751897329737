<eis-gateway-app-shell>
  <div class="page-container">
    <div class="top-content-area">
      <eis-gateway-breadcrumb [breadcrumbItems] = breadcrumbData></eis-gateway-breadcrumb>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h1>{{gatewayDeployment?.gatewaySerial}}</h1>
        <div fxLayout="column" fxLayoutAlign="stretch">
          <eon-ui-button [text]="'gateway-details.page.button.gateway-config' | translate" icon="settings" class="bottom-button"
                         (click)="openGatewayConfig('network')"></eon-ui-button>
          <eon-ui-button *ngIf="supportsDiagnostics" [text]="'gateway-table.popupmenu.diagnostics' | translate" icon="search" class="bottom-button"
          (click)="openDiagnosticsDialog()"></eon-ui-button>
        </div>
      </div>
      <p class="configMap" title="{{'general.copy-to-clipboard' | translate}}" (click)="copyToClipboard(gatewayDeployment?.configmapId)">ConfigMapId: {{gatewayDeployment?.configmapId}} <mat-icon>file_copy</mat-icon></p>
      <p class="imageName">
        Image: {{gatewayDeployment?.imageVersion}}
        <eon-ui-link *ngIf="hasImageUpdate" hide-icon="true" size="small" (click)="openGatewayConfig('softwareupdate')" text="{{'gateway-details.update-available' | translate}}"></eon-ui-link>
      </p>
      <p class="os-version">
        OS Version: {{gatewayDeployment?.osVersion}}
        <eon-ui-link *ngIf="hasOSUpdate" hide-icon="true" size="small" (click)="openGatewayConfig('softwareupdate')" text="{{'gateway-details.update-available' | translate}}"></eon-ui-link>
        <span *ngIf="osUpdateTriggered">
          ({{'gateway-details.os-update-triggered' | translate}})
        </span>
      </p>
      <p class="sub-headline">{{'gateway-details.top-content.subheadline' | translate}}</p>
      <div fxLayout="row" fxLayoutGap="32px" class="status-container-area">
        <div fxLayout="row" class="status-container" fxLayoutAlign="start center" fxLayoutGap="32px">
          <mat-icon *ngIf="mergedGatewayDeployment?.online" class="wifi-on">wifi</mat-icon>
          <mat-icon *ngIf="!mergedGatewayDeployment?.online" class="wifi-off">wifi_off</mat-icon>
          <div fxLayout="column">
            <p class="status-headline" *ngIf="mergedGatewayDeployment?.online">Online</p>
            <p class="status-headline" *ngIf="!mergedGatewayDeployment?.online">Offline</p>
            <p class="status-subheadline">Status</p>
          </div>
        </div>
        <div fxLayout="row" class="status-container" fxLayoutAlign="start center" fxLayoutGap="32px">
          <mat-icon class="status-icon-2" svgIcon="eis-assets-icn-tenant"></mat-icon>
          <div fxLayout="column">
            <p class="status-headline">{{gatewayDeployment?.tenant}}</p>
            <p class="status-subheadline">Tenant</p>
          </div>
        </div>
        <div fxLayout="row" class="status-container" fxLayoutAlign="start center" fxLayoutGap="32px">
          <mat-icon class="status-icon-3">location_on</mat-icon>
          <div fxLayout="column">
            <p class="status-headline">{{gatewayDeployment?.location}}</p>
            <p class="status-subheadline">Location</p>
          </div>
        </div>
        <div fxLayout="row" class="status-container device-count-container" fxLayoutAlign="start center" fxLayoutGap="32px">
          <div fxLayout="column">
            <div fxLayout="row"><label>Modbus/RTU: </label>{{mergedGatewayDeployment?.modbusRTUDeviceCount}} device(s) / {{mergedGatewayDeployment?.modbusRTUDatapointCount}} data point(s)</div>
            <div fxLayout="row"><label>Modbus/TCP:</label> {{mergedGatewayDeployment?.modbusTCPDeviceCount}} device(s) / {{mergedGatewayDeployment?.modbusTCPDatapointCount}} data point(s) </div>
            <div fxLayout="row"><label>Mbus: </label>{{mergedGatewayDeployment?.mbusDeviceCount}} device(s) / {{mergedGatewayDeployment?.mbusDatapointCount}} data point(s) </div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="column" class="bottom-content-area">
      <div fxLayoutAlign="center center" *ngIf="supportMBus">
        <mat-button-toggle-group appearance="legacy" [(ngModel)]="activeProtocol" (ngModelChange)="saveActiveProtocol()">
          <mat-button-toggle value="modbus">Modbus</mat-button-toggle>
          <mat-button-toggle value="mbus">M-Bus</mat-button-toggle>
          <mat-button-toggle value="opcua">OPC UA</mat-button-toggle>
          <mat-button-toggle value="bacnet">BACnet</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div [hidden]="activeProtocol != 'modbus'">
      <mat-tab-group #modbusTabGroup [selectedIndex]="modbusSelectedTabIndex" dynamicHeight mat-stretch-tabs animationDuration="200ms" class="tab-group">
        <mat-tab label="Devices">
          <div *ngIf="devices">
            <div class="add-device-container" fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                {{deviceAmount}} Device(s) found
              </div>
              <div>
                <eon-ui-button [text]="'gateway-details.page.button.add-device' | translate"
                               icon="add" scheme="red" rank="secondary"
                               (click)="openAddDeviceDialog()"></eon-ui-button>
              </div>

            </div>
            <eis-gateway-device-table [tenant]="gatewayDeployment?.tenant" [devices]="devices" [gatewaySerial]="serial"
                                        (updateDatasource)="refresh()"></eis-gateway-device-table>
          </div>
        </mat-tab>
        <mat-tab label="Data Points">
          <div *ngIf="datapoints" class="datapoint-table">
            <eis-gateway-datapoint-table [tenant]="gatewayDeployment?.tenant" [datapoints]="datapoints"
            ></eis-gateway-datapoint-table>
          </div>
        </mat-tab>
      </mat-tab-group>
      </div>

      <div [hidden]="activeProtocol != 'mbus'">
        <mat-tab-group #mbusTabGroup [selectedIndex]="mbusSelectedTabIndex" dynamicHeight mat-stretch-tabs animationDuration="200ms" class="tab-group">
          <mat-tab label="{{'gateway-details.mbus.tab.configurator' | translate}}">
            <eis-gateway-mbus-device-configurator [tenant]="gatewayDeployment?.tenant" [gatewaySerial]="serial" [location]="gatewayDeployment?.location"></eis-gateway-mbus-device-configurator>
          </mat-tab>
          <mat-tab label="{{'gateway-details.mbus.tab.datapoints' | translate}}">
            <div class="datapoint-table">
              <eis-gateway-mbus-detailed-datapoint-table [datapoints]="mbusDatapoints" [gatewaySerial]="serial"
              ></eis-gateway-mbus-detailed-datapoint-table>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

      <div [hidden]="activeProtocol != 'opcua'">
        <mat-tab-group dynamicHeight mat-stretch-tabs animationDuration="200ms" class="tab-group">
        <mat-tab label="Devices">
          <div *ngIf="devices">
            <eis-gateway-opcua-device-table [gatewaySerial]="serial"></eis-gateway-opcua-device-table>
          </div>
        </mat-tab>
        <mat-tab label="Data Points">
        </mat-tab>
        </mat-tab-group>
      </div>

      <div [hidden]="activeProtocol != 'bacnet'">
        <p>The protocol is currently under development.</p>
        <p>Please contact EIS Gateway Product Management (<a href="mailto:frank.stempelman@eon.com">frank.stempelman@eon.com</a>) for further details.</p>
      </div>
    </div>
  </div>
</eis-gateway-app-shell>


