<div mat-dialog-title class="dialog-title">
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="{status: 'closed'}" class=closeX
               name="close"></eon-ui-icon>
  <div class="headline">{{'add-opcua-browser-dialog.title' | translate}}</div>
</div>

<div mat-dialog-content fxFlex class="dialog-content">
  <div fxLayout="row" fxFlex>
    <div class="tree-container">
      <mat-spinner class="initial-loader" diameter="48" strokeWidth="2" color="warn" *ngIf="loadingInitialTree"></mat-spinner>
      <mat-tree class="device-tree" [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node;"
                       [class.selected]="node.id == activeNode?.id"
                       matTreeNodePadding
                       (click)="loadDataPoints(node)">
          <button mat-icon-button matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror" *ngIf="node.expandable">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-spinner diameter="16" strokeWidth="1" color="warn" *ngIf="node.isLoading"></mat-spinner>
          {{node.name}}
        </mat-tree-node>
      </mat-tree>
    </div>


    <div class="table-container">
      <table mat-table [dataSource]="dataPoints" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="toggleAllRows()"
                          [checked]="isAllSelected()"
                          [indeterminate]="deviceAddedDataPoints.length > 0 && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="toggle(row)"
                          [checked]="isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="browseName">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="browseName">{{'opcua-datapoint-table.header.browse-name' | translate}}</th>

          <td mat-cell *matCellDef="let dataPoint">
            {{dataPoint.browse_name}}
          </td>

        </ng-container>

        <ng-container matColumnDef="dataType">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="dataType">Data Type</th>

          <td mat-cell *matCellDef="let dataPoint">
            {{dataPoint.data_type}}
          </td>

        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="name">Name</th>

          <td mat-cell *matCellDef="let dataPoint">
            {{dataPoint.name}}
          </td>

        </ng-container>

        <ng-container matColumnDef="nodeId">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="nodeId">{{'opcua-datapoint-table.header.node-id' | translate}}</th>

          <td mat-cell *matCellDef="let dataPoint">
            {{dataPoint.node_id}}
          </td>

        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="value">Value</th>

          <td mat-cell *matCellDef="let dataPoint">
            {{dataPoint.value}}
          </td>

        </ng-container>

        <ng-container matColumnDef="pollingFrequency">
          <th mat-header-cell *matHeaderCellDef>Polling Frequency</th>

          <td mat-cell *matCellDef="let dataPoint">
            <mat-form-field appearance="outline" class="eon-style-form-field no-padding polling-frequency-field">
              <input type="number" matInput [(ngModel)]="pollingFrequencies[dataPoint.node_id]">
            </mat-form-field>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          [class.removed]="isDeleted(row)"
          [class.added]="isNew(row)"
          *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>

<div mat-dialog-actions style="justify-content: space-between;">
  <div></div>
  <eon-ui-button [text]="'mbus-datapoint-configurator.button.apply' | translate"
                 icon="radio_checkbox_tick" scheme="red"
                 (click)="applyChanges()"></eon-ui-button>
</div>

