import { Component, Inject, OnInit } from '@angular/core';
import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections';
import { BehaviorSubject, firstValueFrom, map, merge, Observable } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { OPCUADeviceService } from '../../../../services/opcua-device.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OPCUADevice } from '../../../../domain/opcua-device';
import { OPCUATreeNode } from '../../../../domain/opcua-tree-node';
import { OPCUADataPoint } from '../../../../domain/opcua-data-point';
import { OPCUAConfig } from '../../../../domain/opcua-config';
import { MBusDataPoint } from '../../../../domain/device.interface';
import { NotificationService } from '../../../../services/notification.service';

/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(
    public name: string,
    public id: string,
    public level = 1,
    public expandable = false,
    public isLoading = false,
  ) {}
}

export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);
  private cachedChildren = new Map<string, OPCUATreeNode[]>();

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }
  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private device: OPCUADevice,
    private _treeControl: FlatTreeControl<DynamicFlatNode>,
    private _deviceService: OPCUADeviceService,
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe(change => {
      if (
        (change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {}

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  async toggleNode(node: DynamicFlatNode, expand: boolean) {
    const index = this.data.indexOf(node);

    node.isLoading = expand;

    if (expand) {
      let children = this.cachedChildren.get(node.id);
      if(!children) {
        children = await firstValueFrom(this._deviceService.browseChildren(this.device, node.id));
        this.cachedChildren.set(node.id, children);
      }
      if(!children) {
        children = [];
      }

      const nodes = children.map(
        child => new DynamicFlatNode(child.node_name, child.node_id, node.level + 1, child.has_children),
      );
      this.data.splice(index + 1, 0, ...nodes);
      if(nodes.length == 0) {
        node.expandable = false;
      }
    } else {
      let count = 0;
      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++ ) {}
      this.data.splice(index + 1, count);
    }

    // notify the change
    this.dataChange.next(this.data);
    node.isLoading = false;
  }
}


@Component({
  selector: 'eis-gateway-opcua-browser-dialog',
  templateUrl: './opcua-browser-dialog.component.html',
  styleUrls: ['./opcua-browser-dialog.component.scss']
})
export class OpcuaBrowserDialogComponent implements OnInit {
  public treeControl: FlatTreeControl<DynamicFlatNode>;
  public dataSource: DynamicDataSource;
  public dataPoints: OPCUADataPoint[] = [];
  public deviceAddedDataPoints: OPCUADataPoint[] = [];
  public deviceRemovedDataPoints: OPCUADataPoint[] = [];
  public activeNode: DynamicFlatNode;
  public pollingFrequencies: {[id: string]: string} = {};
  public loadingInitialTree: boolean = true;
  private opcuaConfig: OPCUAConfig[] = [];

  //public displayedColumns: string[] = ['browseName', 'dataType', 'name', 'nodeId', 'value'];
  public displayedColumns: string[] = ['select', 'name', 'value', 'pollingFrequency'];
  private START_NODE_ID = "ns=0;i=85";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {device: OPCUADevice, activeSerial: string},
    public dialogRef: MatDialogRef<OpcuaBrowserDialogComponent>,
    private deviceService: OPCUADeviceService,
    private notificationService: NotificationService,
  ) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(data.device, this.treeControl, deviceService);
  }

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;

  async ngOnInit(): Promise<void> {
    this.data.device.gatewaySerial = this.data.activeSerial;
    this.opcuaConfig = await firstValueFrom(this.deviceService.getDeviceConfig(this.data.activeSerial, this.data.device.id));

    this.loadingInitialTree = true;
    let parents = await firstValueFrom(this.deviceService.browseChildren(this.data.device, this.START_NODE_ID));
    this.loadingInitialTree = false;
    if(parents == null) {
      parents = [];
    }
    this.dataSource.data = parents.map(
      parent => new DynamicFlatNode(parent.node_name, parent.node_id, 1, parent.has_children),
    );
  }

  async loadDataPoints(node: DynamicFlatNode) {
    this.activeNode = node;
    this.dataPoints = await firstValueFrom(this.deviceService.getDataPoints(this.data.device, node.id));
    if(this.dataPoints == null) {
      this.dataPoints = [];
    }

    this.selectAddedDataPoints();
    this.fillPollingFrequencies();
    this.dataPoints = this.sortDataPointsBySelection(this.dataPoints);
  }

  async applyChanges() {
    const updatedConfigs: OPCUAConfig[] = [];
    for (let dp of this.deviceAddedDataPoints) {
      const config = this.getConfig(dp);
      updatedConfigs.push({
        id: config?.id,
        nodeId: dp.node_id,
        browseName: dp.browse_name,
        pollingFrequency: parseInt(this.pollingFrequencies[dp.node_id]),
        scaleFactor: 1,
        actionType: "add"
      } as OPCUAConfig);
    }

    for (let dp of this.deviceRemovedDataPoints) {
      const config = this.getConfig(dp);
      if(!config?.actionType) {
        updatedConfigs.push({
          id: config?.id,
          nodeId: dp.node_id,
          browseName: dp.browse_name,
          pollingFrequency: parseInt(this.pollingFrequencies[dp.node_id]),
          scaleFactor: 1,
          actionType: "delete"
        } as OPCUAConfig);
      }
    }

    const savedConfigs = await firstValueFrom(this.deviceService.updateDeviceConfig(this.data.activeSerial, this.data.device.id, updatedConfigs));
    for(let config of savedConfigs) {
      this.opcuaConfig = this.opcuaConfig.filter(c => c.id != config.id);
      this.opcuaConfig.push(config);
    }

    const removedConfigs: OPCUAConfig[] = [];
    for (let dp of this.deviceRemovedDataPoints) {
      const config = this.getConfig(dp);
      if(config?.actionType == "add") {
        removedConfigs.push({
          id: config?.id,
          nodeId: dp.node_id,
          browseName: dp.browse_name,
          pollingFrequency: parseInt(this.pollingFrequencies[dp.node_id]),
          scaleFactor: 1
        } as OPCUAConfig);
      }
    }

    await firstValueFrom(this.deviceService.removeDeviceConfig(this.data.activeSerial, this.data.device.id, removedConfigs));
    for(let config of removedConfigs) {
      this.opcuaConfig = this.opcuaConfig.filter(c => c.id != config.id);
    }
    this.deviceRemovedDataPoints = [];

    this.notificationService.success('Data points have been saved successfully');
  }

  toggleAllRows() {
    if (this.isAllSelected() || this.isNoneSelected()) {
      for(let i = 0; i < this.dataPoints.length; i++) {
        this.toggle(this.dataPoints[i]);
      }
    }
    else {
      for(let i = 0; i < this.dataPoints.length; i++) {
        if(this.findDataPointIndex(this.deviceAddedDataPoints, this.dataPoints[i]) == -1) {
          this.toggle(this.dataPoints[i]);
        }
      }
    }
  }

  isAllSelected() {
    const numSelected = this.deviceAddedDataPoints.length;
    const numRows = this.dataPoints.length;
    return numSelected === numRows;
  }

  isNoneSelected() {
    return this.deviceAddedDataPoints.length == 0;
  }

  findDataPointIndex(dataPoints: OPCUADataPoint[], point: OPCUADataPoint): number {
    return dataPoints.findIndex(dt => dt.node_id == point.node_id);
  }

  isSelected(dataPoint: OPCUADataPoint): boolean {
    return this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint) != -1;
  }

  toggle(dataPoint: OPCUADataPoint) {
    let index = this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint);
    if(index > -1) {
      this.deviceAddedDataPoints.splice(index, 1);
      this.pollingFrequencies[dataPoint.node_id] = "";

      if(this.getConfig(dataPoint)) {
        this.deviceRemovedDataPoints.push(dataPoint);
      }
    } else {
      this.deviceAddedDataPoints.push(dataPoint);
      const config = this.getConfig(dataPoint);
      this.pollingFrequencies[dataPoint.node_id] = config?.pollingFrequency ? config?.pollingFrequency + "" : "60";

      index = this.findDataPointIndex(this.deviceRemovedDataPoints, dataPoint);
      if(index > -1) {
        this.deviceRemovedDataPoints.splice(index, 1);
      }
    }
  }

  private getConfig(dataPoint: OPCUADataPoint) : OPCUAConfig | undefined {
    return this.opcuaConfig.find(conf => conf.nodeId == dataPoint.node_id);
  }

  private fillPollingFrequencies() {
    this.pollingFrequencies = {};
    for(let dataPoint of this.deviceAddedDataPoints) {
      const config = this.getConfig(dataPoint);
      this.pollingFrequencies[dataPoint.node_id] = config?.pollingFrequency ? config?.pollingFrequency + "" : "60";
    }
  }

  private selectAddedDataPoints() {
    this.deviceAddedDataPoints = this.dataPoints.filter(d => this.opcuaConfig.find(c => c.nodeId == d.node_id));
  }

  private sortDataPointsBySelection(dataPoints: OPCUADataPoint[]): OPCUADataPoint[] {
    const selectedDataPoints = this.deviceAddedDataPoints;
    return dataPoints.sort((a, b) =>
      this.findDataPointIndex(selectedDataPoints, a) > -1 && this.findDataPointIndex(selectedDataPoints, b) == -1
        ? -1
        : this.findDataPointIndex(selectedDataPoints, b) > -1 && this.findDataPointIndex(selectedDataPoints, a) == -1
          ? 1
          : parseInt(a.browse_name) > parseInt(b.browse_name)
            ? -1 : 1
    );
  }

  isNew(dataPoint: OPCUADataPoint): boolean {
    const config = this.getConfig(dataPoint);
    return this.findDataPointIndex(this.deviceAddedDataPoints, dataPoint) != -1 && (!config || config.actionType == "add");
  }

  isAdded(dataPoint: OPCUADataPoint): boolean {
    const config = this.getConfig(dataPoint);
    return !!config && config.actionType != "delete";
  }

  isDeleted(dataPoint: OPCUADataPoint): boolean {
    return this.findDataPointIndex(this.deviceRemovedDataPoints, dataPoint) != -1;
  }
}
