<div class="table-container">
  <table mat-table matSort [dataSource]="configs">
    <ng-container matColumnDef="deviceName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="deviceName">Device name</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.device.name}}</td>
    </ng-container>

    <ng-container matColumnDef="browseName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="browseName">Browse name</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.browseName}}</td>
    </ng-container>

    <ng-container matColumnDef="pollingFrequency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="pollingFrequency">{{'mbus-config-table.header.polling-frequency' | translate}}</th>
      <td mat-cell *matCellDef="let datapoint">{{datapoint.pollingFrequency}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
