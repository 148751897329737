import { Component, Input, OnInit } from '@angular/core';
import { OPCUADeviceService } from '../../../../services/opcua-device.service';
import { OPCUADevice } from '../../../../domain/opcua-device';
import { AddMbusDeviceDialogComponent } from '../../mbus/add-mbus-device-dialog/add-mbus-device-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { OpcuaBrowserDialogComponent } from '../opcua-browser-dialog/opcua-browser-dialog.component';
import { AddOpcuaDeviceDialogComponent } from '../add-opcua-device-dialog/add-opcua-device-dialog.component';
import { NotificationService } from '../../../../services/notification.service';
import { MbusPreviewDatapointsComponent } from '../../mbus/mbus-preview-datapoints/mbus-preview-datapoints.component';
import { OpcuaPreviewChangesComponent } from '../opcua-preview-changes/opcua-preview-changes.component';
import { MBusDataPoint } from '../../../../domain/device.interface';
import { OPCUAConfig } from '../../../../domain/opcua-config';

@Component({
  selector: 'eis-gateway-opcua-device-table',
  templateUrl: './opcua-device-table.component.html',
  styleUrls: ['./opcua-device-table.component.scss']
})
export class OpcuaDeviceTableComponent implements OnInit {
  @Input()
  public gatewaySerial: string | undefined;

  public devices: OPCUADevice[] = [];

  public displayedColumns: string[] = ['hasUndeployedDataPoints', 'online', 'deviceName', 'serverAddress', 'portNumber', 'menuAction'];

  constructor(
    private opcuaDeviceService: OPCUADeviceService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.getOPCUADevices();
  }

  private getOPCUADevices() {
    this.opcuaDeviceService.getAllDevices(this.gatewaySerial).subscribe(devices => this.devices = devices);
  }

  addOPCUADevice() {
    const dialogRef = this.dialog.open(AddOpcuaDeviceDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        activeSerial: this.gatewaySerial,
        device: null
      },
      width: '692px',
      height: '736px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if(res.status == "200") {
        this.getOPCUADevices();
        this.notificationService.success('gateway-details.snackbar.device-add.success');
      } else {
        this.notificationService.failure(res.error)
      }
    });
  }

  deviceClicked(device: OPCUADevice) {
    const dialogRef = this.dialog.open(OpcuaBrowserDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        device: device,
        activeSerial: this.gatewaySerial
      },
      width: '90%',
      height: '864px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((_) => {
      this.getOPCUADevices();
    });
  }

  hasChanges(): boolean {
    return !!this.devices.find(d => d.hasUndeployedDataPoints);
  }

  previewUndeployedChanges() {
    let deviceIds = this.devices.map(m => m.id);
    deviceIds = [...new Set(deviceIds)];
    this.opcuaDeviceService.getGatewayConfig(this.gatewaySerial!, deviceIds).subscribe(configs => {
      if (configs == null) {
        configs = [];
      }

      const addedConfigs:OPCUAConfig[] = [];
      const updatedConfigs:OPCUAConfig[] = [];
      const removedConfigs:OPCUAConfig[] = [];

      const undeployedConfigs = configs.filter(c => c.isDeployed != true);
      for (let config of undeployedConfigs) {
        if (config.actionType == "add") {
          addedConfigs.push(config);
        } else if (config.actionType == "update") {
          updatedConfigs.push(config);
        } else if (config.actionType == "delete") {
          removedConfigs.push(config);
        }
      }

      const dialogRef = this.dialog.open(OpcuaPreviewChangesComponent, {
        panelClass: 'dialog-container-custom',
        disableClose: true,
        data: {
          addedConfigs: addedConfigs,
          updatedConfigs: updatedConfigs,
          removedConfigs: removedConfigs,
          removedDevices: [],
          serial: this.gatewaySerial,
        },
        width: '850px',
        height: '910px',
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.getOPCUADevices();
        }
      });
    });
  }
}
